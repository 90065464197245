<template>
  <div class="page-wrapper">
    <section class="hero_block policies">
      <h1 class="title small" data-inview="fadeInUp" data-delay="200">PineStone</h1>
      <h2 class="title" data-inview="fadeInUp" data-delay="300" v-html="$t('policies-title')"></h2>
      <div class="line"></div>
      <div class="overlay"></div>
    </section>

    <section class="stats_wrap">
      <div class="stat empty" data-inview="fadeInUp" data-delay="200"></div>

      <div v-if="$route.meta.lang == 'en'" class="stat larger" data-inview="fadeInUp" data-delay="300">
        <p class="big invert sup">
          <span class="bottom">Firmwide Pinestone Disclosure policies</span>
        </p>

        <div class="table">
          <a href="/pdf/code-of-ethics-and-professional-conduct.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Code of Ethics and Professional Conduct</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/conflicts-of-interest-disclosure-280824.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Conflicts of Interest Disclosure</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/confidentiality-policy-new-2024.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Confidentality Policy</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/complaints-policy.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Complaints Policy</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/fairness-policy.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Fairness Policy</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/proxy-voting-guidelines.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Proxy Voting Guidelines</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/authorized-signatories.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Authorized Signatories</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>
      </div>

      <div v-if="$route.meta.lang == 'fr'" class="stat larger" data-inview="fadeInUp" data-delay="300">
        <p class="big invert sup">
          <span class="bottom">Politiques de l'entreprise</span>
        </p>

        <div class="table">
          <a href="/pdf/code-de-deontologie-et-de-conduite-professionnelle.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Code de déontologie et de conduite professionnelle</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/information-sur-les-conflits-de-interets-280824-2.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Information sur les conflits d'intérêts</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/politique-de-confidentalite-new-2024.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Politique de confidentalité</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/politique-de-plaintes.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Politique de plaintes</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/politique-d-equite.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Politique d’équité</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/lignes-directrices-sur-le-vote-par-procuration.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Lignes directrices sur le vote par procuration</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>

          <a href="/pdf/signataires-autorises.pdf" target="_blank" rel="noopener noreferrer" class="row file">
            <div class="cell color">
              <p>Signataires autorisés</p>
            </div>
            <div class="cell">
              <p class="download">
                <span>{{ $t('Download') }}&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-download"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <line x1="12" y1="11" x2="12" y2="17" />
                  <polyline points="9 14 12 17 15 14" />
                </svg>
              </p>
            </div>
          </a>
        </div>
      </div>

      <div class="stat empty" data-inview="fadeInUp" data-delay="400"></div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: this.$t(`policies-title`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          ),
          id: 'desc'
        },
        { name: 'application-name', content: this.$t(`policies-title`) },
        {
          name: 'twitter:title',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`policies-title`) },
        { property: 'og:site_name', content: this.$t(`policies-title`) },
        {
          property: 'og:description',
          content: this.$t(
            `As long-term investors, PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company.`
          )
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://pinestoneam.com/img/pinestone-og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://pinestoneam.com/img/pinestone-og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
